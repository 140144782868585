<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">預約資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="申請日期" :fieldValue.sync="formData.create_date" required disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="狀態"
                :fieldValue.sync="formData.appointment_status"
                :options="$appointmentStatusOptions"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-date-picker label="預約日期" :fieldValue.sync="formData.date" required disabledClear />
            </v-col>
            <v-col cols="12" md="6">
              <form-time-picker
                label="預約時間"
                :fieldValue.sync="formData.time"
                required
                min="10:00"
                max="20:00"
                disabledClear
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="備註" :fieldValue.sync="formData.remark" :disabled="disabledRemark" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">客戶資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="姓名" :fieldValue.sync="userData.name" required disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="電話" :fieldValue.sync="userData.phone" required type="tel" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="電郵地址" :fieldValue.sync="userData.email" required type="email" disabled />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'
import FormTimePicker from '@/components/form/FormTimePicker.vue'

export default {
  name: 'AppointmentDetail',
  components: {
    FormInput,
    FormSelect,
    FormDatePicker,
    FormTimePicker,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
  },
  data: () => ({
    formData: {
      create_date: '',
      appointment_status: 'active',
      date: '',
      time: '',
      remark: '',
    },

    userData: {
      name: '',
      phone: '',
      email: '',
      id: null,
    },

    disabledRemark: false,
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetAppointmentById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }

        if (data.remark === undefined) {
          this.disabledRemark = true;
        }

        this.formData.create_date = this.$Formatter.displayCreateDate(data.create_date)
        this.formData.date = data.appointment_date.substring(0, 10)
        this.formData.time = data.appointment_date.substring(11, 16)

        if (data.user_data) {
          for (const key in this.userData) {
            if (this.$validate.DataValid(data.user_data[key])) {
              this.$set(this.userData, key, data.user_data[key])
            }
          }
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'Appointment',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'Appointment',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }
      this.$store.dispatch('setLoading', true);
      let payload = {
        appointment_status: this.formData.appointment_status,
        datetime: `${this.formData.date}T${this.formData.time}:00Z`,
        user_id: this.userData.id,
      }

      if (!this.disabledRemark) {
        payload['remark'] = this.formData.remark;
      }

      const user = this.getCurrentUserData()
      try {
        await this.$Fetcher.UpdateAppointment(this.id, payload, user.id, user.token)

        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleSnack', {
          type: 'success',
          message: '儲存成功',
          refresh: true,
        })
      } catch (err) {
        this.setAlert({
          title: '儲存失敗',
          message: err,
          type: 'error',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
